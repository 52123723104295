import React from "react";
import { useTranslation ,Trans} from "react-i18next";

const Content: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex  flex-col lg:flex-row gap-28">
      <div className="flex flex-col gap-6">
        <h3>
          {t("culture.content.one.heading")}
          <span className="blue-text">
            {t("culture.content.one.headingBlue")}
          </span>
        </h3>
        <div className="body-text-1">{t("culture.content.one.body")}</div>
      </div>
      <div className="flex flex-col gap-6">
        <h3>
          {t("culture.content.two.heading")}
          <span className="blue-text">
            {t("culture.content.two.headingBlue")}
          </span>
        </h3>
        <div className="body-text-1">
        <Trans i18nKey="culture.content.two.body">
        Energy is contagious. We expect our team members to give their 100% to every interaction, every task, every day. This takes work, but it also takes a lot of not
{" "}
            <i>
            not
            </i>
            working. We give every individual full responsibility for their output so they can get 100% out of our various employee programs, holidays, and benefits. You have our back, and we've got yours
          </Trans>
          </div>
      </div>
    </div>
  );
};

export default Content;
