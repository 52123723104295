import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Info from "../components/common/Info";
import SEO from "../components/common/seo";
import Footer from "../components/home/Footer";
import Header from "../components/home/Header";
import CultureContent from "../components/Culture/Content";
import WorkingAtHatio from "../components/Culture/WorkingAtHatio";
import "../i18n";
import "./culture.css";

const Culture = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        props={{
          title: t("culture.seoTitle"),
          path: "/culture",
        }}
      />
      <Header openModal={() => {}} />
      <div className="container mx-auto flex flex-col items-center justify-center culture-banner">
        <div className="text-center culture-banner-container">
          <h2 className="culture-banner-heading font-semibold text-neutral-oxford-blue max-w-[918px] px-0 md:px-24">
            <Trans i18nKey="bannerHeading">
              We help companies scale, and employees <span>grow.</span>
            </Trans>
          </h2>
          <div className="body-text-1 sm:flex sm:justify-center culture-banner-content px-10">
            {t("culture.paraAbout")}
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="culture-info">
          <Info
            title={
              <Trans i18nKey="infoText">
                The world comprises thinkers and doers. We only work with
                thinkers who are big on the <i>doing</i>.
              </Trans>
            }
          />
        </div>
        <div className="culture-content-wrap">
          <CultureContent />
        </div>
        <div className="culture-working-info">
          <WorkingAtHatio />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Culture;
