import React from "react";
import { useTranslation, Trans } from "react-i18next";
import "./style.css";
import WorkingAtHatioImg from "../../../assets/culture_team.png";

const WorkingAtHatio: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-celtic-blue-light-tint-2 flex flex-col gap-8 lg:px-16 p-10 rounded-lg working-hatio-wrapper">
      <h3>{t("culture.workingAtHatio.heading")}</h3>
      <div className="working-at-hatio">
        <img
          className="w-full"
          src={WorkingAtHatioImg}
          alt="Working at hatio"
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="body-text-1 working-content">
          {t("culture.workingAtHatio.body")}
        </div>
        <div className="body-text-1 bold-font mt-9 working-highlight">
          <Trans i18nKey="bodyBold">
            If you are inquisitive, analytical, and optimistic that technology
            can bring about meaningful change, do explore our current open roles
            in the{" "}
            <a href="/careers" className="blue-text">
              Careers page
            </a>
            .
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default WorkingAtHatio;
